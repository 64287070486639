import React, { useState, useEffect } from "react";
import "./MonthlyWorker.css";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import loginStore from "redux/stores/LoginStore.js";
import Datetime from "react-datetime";
import PropTypes from "prop-types";
import { createMonthlyWorkerVoice } from "redux/actions/TeamActivityActions.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import moment from "moment";
import { updateMomentLocale } from "languages/i18n/utilities/utilities";

const customStyles = {
  ...sweetAlertStyles,
  label: {
    color: "black",
    fontSize: "16px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.42857,
  },
  customGridItem: {
    marginTop: "-20px",
  },
  input: {
    padding: "5px",
  },
};

const useStyles = makeStyles(customStyles);

export default function MonthlyWorkerVoiceForm(props, context) {
  const dispatch = useDispatch();
  const classes = useStyles();
  let _user = loginStore.getLoginUser();
  const [monthYear, setMonthYear] = useState(null);
  const [mmHotlineTotal, setMmHotlineTotal] = useState(0);
  const [burmeseTiktokMessages, setBurmeseTiktokMessages] = useState(0);
  const [nepaleseTiktokMessages, setNepaleseTiktokMessages] = useState(0);
  const [khmerTiktokMessages, setKhmerTiktokMessages] = useState(0);
  const [hotlineInTaiwan, setHotlineInTaiwan] = useState(0);
  const [
    bangladeshiFacebookMessenger,
    setBangladeshiFacebookMessenger,
  ] = useState(0);
  const [bangladeshiHotLine, setBangladeshiHotLine] = useState(0);
  const [bangladeshiWhatsapp, setBangladeshiWhatsapp] = useState(0);
  const [bangladeshiTiktokMessages, setBangladeshiTiktokMessages] = useState(0);
  const [yangonHotlineTotal, setYangonHotlineTotal] = useState(0);
  const [mmFbTotal, setMmFbTotal] = useState(0);
  const [mmLineTotal, setMmLineTotal] = useState(0);
  const [mmViberTotal, setMmViberTotal] = useState(0);
  const [cbHotlineTotal, setCbHotlineTotal] = useState(0);
  const [cbFbTotal, setCbFbTotal] = useState(0);
  const [cbLineTotal, setCbLineTotal] = useState(0);
  const [cbViberTotal, setCbViberTotal] = useState(0);
  const [thaiHotlineTotal, setThaiHotlineTotal] = useState(0);
  const [thaiFbTotal, setThaiFbTotal] = useState(0);
  const [thaiLineTotal, setThaiLineTotal] = useState(0);
  const [thaiViberTotal, setThaiViberTotal] = useState(0);
  const [myIndonesianWhatsappTotal, setMyIndonesianWhatsappTotal] = useState(0);
  const [myIndonesianHotlineTotal, setMyIndonesianHotlineTotal] = useState(0);
  const [myNepalWhatsappTotal, setMyNepalWhatsappTotal] = useState(0);
  const [nepalHotlineTotal, setNepalHotlineTotal] = useState(0);
  const [nepalFacebookTotal, setNepalFacebookTotal] = useState(0);
  const [myBurmeseWhatsappTotal, setBurmeseWhatsappTotal] = useState(0);
  const [myBurmeseViberTotal, setBurmeseViberTotal] = useState(0);

  const lang = useSelector((state) => state.i18nState.lang);

  const [alert, setAlert] = useState(null);

  const handleConfirmSuccessAlert = () => {
    props.history.push("/admin/issara-dashboard");
  };

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        onConfirm={handleConfirmSuccessAlert}
        confirmBtnCssClass={classes.button + " " + classes.success}
        title={context.t("Well done!")}
      >
        {context.t(
          "New monthly worker voice record has been successfully created"
        )}
      </SweetAlert>
    );
  };

  const errorAlert = (error) => {
    console.log("ERRROR", error);
    setAlert(
      <SweetAlert
        danger
        onConfirm={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        title={context.t("Error")}
      >
        {error.message}
      </SweetAlert>
    );
  };

  const handleDateChange = (date) => {
    if (moment.isMoment(date)) {
      const newDate = date.startOf("month").toDate();
      setMonthYear(newDate);
    }
  };

  const onSubmit = () => {
    const payload = {
      month_year:
        monthYear instanceof Date ? monthYear.toLocaleDateString() : null,
      mm_hotline_total: mmHotlineTotal,
      burmese_titok_messages: burmeseTiktokMessages,
      nepalese_tiktok_messages: nepaleseTiktokMessages,
      hotline_in_taiwan: hotlineInTaiwan,
      khmer_tiktok: khmerTiktokMessages,
      bangladeshi_facebook_messenger: bangladeshiFacebookMessenger,
      bangladeshi_hotline: bangladeshiHotLine,
      bangladeshi_whatsapp: bangladeshiWhatsapp,
      bangladeshi_tiktok_messages: bangladeshiTiktokMessages,
      yangon_hotline_total: yangonHotlineTotal,
      mm_fb_total: mmFbTotal,
      mm_line_total: mmLineTotal,
      mm_viber_total: mmViberTotal,
      cb_hotline_total: cbHotlineTotal,
      cb_fb_total: cbFbTotal,
      cb_line_total: cbLineTotal,
      cb_viber_total: cbViberTotal,
      thai_hotline_total: thaiHotlineTotal,
      thai_fb_total: thaiFbTotal,
      thai_line_total: thaiLineTotal,
      thai_viber_total: thaiViberTotal,
      my_indonesian_whatsapp_total: myIndonesianWhatsappTotal,
      my_indonesia_hotline_total: myIndonesianHotlineTotal,
      my_nepal_whatsapp_total: myNepalWhatsappTotal,
      nepal_hotline_total: nepalHotlineTotal,
      nepal_facebook_total: nepalFacebookTotal,
      my_burmese_whatsapp_total: myBurmeseWhatsappTotal,
      my_burmese_viber_total: myBurmeseViberTotal,
    };

    dispatch(createMonthlyWorkerVoice(payload, successAlert, errorAlert));
  };

  useEffect(() => {
    if (lang) {
      updateMomentLocale(lang);
    }
  }, []);

  return (
    <>
      <GridItem xs={12} sm={12} lg={12}>
        <Card style={{ marginTop: 0 }}>
          {alert}
          <CardBody>
            <GridContainer>
              <GridItem lg={3}>
                <p className={classes.label} style={{ marginBottom: 0 }}>
                  {context.t("Month Year")}
                </p>
                <Datetime
                  locale="en"
                  dateFormat="YYYY-MM"
                  inputProps={{
                    placeholder: context.t("Click here to open calendar"),
                  }}
                  closeOnSelect={true}
                  onChange={handleDateChange}
                  value={moment(monthYear)}
                  renderDay={() => null}
                />
              </GridItem>
              <GridContainer
                justify="center"
                style={{ padding: 0 }}
                className="changeLableStyle"
              >
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Burmese Titok messages")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setBurmeseTiktokMessages(
                          e.target.value > 0 ? e.target.value : 0
                        );
                      },
                    }}
                    value={`${parseInt(burmeseTiktokMessages)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Nepalese Tiktok messages")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setNepaleseTiktokMessages(
                          e.target.value > 0 ? e.target.value : 0
                        );
                      },
                    }}
                    value={`${parseInt(nepaleseTiktokMessages)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Khmer TikTok")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setKhmerTiktokMessages(
                          e.target.value > 0 ? e.target.value : 0
                        );
                      },
                    }}
                    value={`${parseInt(khmerTiktokMessages)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Hotline in Taiwan")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setHotlineInTaiwan(
                          e.target.value > 0 ? e.target.value : 0
                        );
                      },
                    }}
                    value={`${parseInt(hotlineInTaiwan)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Bangladeshi Facebook messenger")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setBangladeshiFacebookMessenger(
                          e.target.value > 0 ? e.target.value : 0
                        );
                      },
                    }}
                    value={`${parseInt(bangladeshiFacebookMessenger)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Bangladeshi hotline")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setBangladeshiHotLine(
                          e.target.value > 0 ? e.target.value : 0
                        );
                      },
                    }}
                    value={`${parseInt(bangladeshiHotLine)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Bangladeshi Whatsapp")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setBangladeshiWhatsapp(
                          e.target.value > 0 ? e.target.value : 0
                        );
                      },
                    }}
                    value={`${parseInt(bangladeshiWhatsapp)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Bangladeshi Tiktok messages")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setBangladeshiTiktokMessages(
                          e.target.value > 0 ? e.target.value : 0
                        );
                      },
                    }}
                    value={`${parseInt(bangladeshiTiktokMessages)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Burmese hotline in Thailand")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setMmHotlineTotal(
                          e.target.value > 0 ? e.target.value : 0
                        );
                      },
                    }}
                    value={`${parseInt(mmHotlineTotal)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Burmese hotline in Myanmar")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setYangonHotlineTotal(
                          e.target.value > 0 ? e.target.value : 0
                        );
                      },
                    }}
                    value={`${parseInt(yangonHotlineTotal)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Burmese Facebook messenger")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setMmFbTotal(e.target.value > 0 ? e.target.value : 0);
                      },
                    }}
                    value={`${parseInt(mmFbTotal)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Burmese LINE messages")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setMmLineTotal(e.target.value > 0 ? e.target.value : 0);
                      },
                    }}
                    value={`${parseInt(mmLineTotal)}`}
                  />
                </GridItem>

                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Khmer hotline")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setCbHotlineTotal(
                          e.target.value > 0 ? e.target.value : 0
                        );
                      },
                    }}
                    value={`${parseInt(cbHotlineTotal)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Khmer Facebook messenger")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setCbFbTotal(e.target.value > 0 ? e.target.value : 0);
                      },
                    }}
                    value={`${parseInt(cbFbTotal)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Khmer LINE")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setCbLineTotal(e.target.value > 0 ? e.target.value : 0);
                      },
                    }}
                    value={`${parseInt(cbLineTotal)}`}
                  />
                </GridItem>
                {_user && _user.groups.includes("Issara Staff") && (
                  <GridItem className={classes.customGridItem}>
                    <CustomInput
                      labelText={context.t("Khmer Telegram")}
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        className: classes.input,
                        type: "number",
                        onChange: (e) => {
                          setCbViberTotal(
                            e.target.value > 0 ? e.target.value : 0
                          );
                        },
                      }}
                      value={`${parseInt(cbViberTotal)}`}
                    />
                  </GridItem>
                )}
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Thai Hotline")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setThaiHotlineTotal(
                          e.target.value > 0 ? e.target.value : 0
                        );
                      },
                    }}
                    value={`${parseInt(thaiHotlineTotal)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Thai Facebook Messenger")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setThaiFbTotal(e.target.value > 0 ? e.target.value : 0);
                      },
                    }}
                    value={`${parseInt(thaiFbTotal)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Thai LINE")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setThaiLineTotal(
                          e.target.value > 0 ? e.target.value : 0
                        );
                      },
                    }}
                    value={`${parseInt(thaiLineTotal)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Burmese Viber in Thailand")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setThaiViberTotal(
                          e.target.value > 0 ? e.target.value : 0
                        );
                      },
                    }}
                    value={`${parseInt(thaiViberTotal)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Indonesian WhatsApp in Malaysia")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setMyIndonesianWhatsappTotal(
                          e.target.value > 0 ? e.target.value : 0
                        );
                      },
                    }}
                    value={`${parseInt(myIndonesianWhatsappTotal)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Indonesian hotline in Malaysia")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setMyIndonesianHotlineTotal(
                          e.target.value > 0 ? e.target.value : 0
                        );
                      },
                    }}
                    value={`${parseInt(myIndonesianHotlineTotal)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Nepalese WhatsApp")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setMyNepalWhatsappTotal(
                          e.target.value > 0 ? e.target.value : 0
                        );
                      },
                    }}
                    value={`${parseInt(myNepalWhatsappTotal)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Nepalese hotline")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setNepalHotlineTotal(
                          e.target.value > 0 ? e.target.value : 0
                        );
                      },
                    }}
                    value={`${parseInt(nepalHotlineTotal)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Nepalese Facebook Messenger")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setNepalFacebookTotal(
                          e.target.value > 0 ? e.target.value : 0
                        );
                      },
                    }}
                    value={`${parseInt(nepalFacebookTotal)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Burmese WhatsApp in Malaysia")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setBurmeseWhatsappTotal(
                          e.target.value > 0 ? e.target.value : 0
                        );
                      },
                    }}
                    value={`${parseInt(myBurmeseWhatsappTotal)}`}
                  />
                </GridItem>
                <GridItem className={classes.customGridItem}>
                  <CustomInput
                    labelText={context.t("Burmese Viber in Malaysia")}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        setBurmeseViberTotal(
                          e.target.value > 0 ? e.target.value : 0
                        );
                      },
                    }}
                    value={`${parseInt(myBurmeseViberTotal)}`}
                  />
                </GridItem>
              </GridContainer>
            </GridContainer>

            <GridContainer justify="flex-end">
              <GridItem>
                <Button color="success" onClick={onSubmit}>
                  {context.t("Save")}
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </>
  );
}

MonthlyWorkerVoiceForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
