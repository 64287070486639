import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import HttpService from "../../services/HttpService";
import SweetAlert from "react-bootstrap-sweetalert";
import Datetime from "react-datetime";
import "./MonthlyWorker.css";
import PropTypes from "prop-types";

const customStyles = {
  customGridItem: {
    width: "330px",
  },
  input: {
    padding: "5px",
    paddingTop: "12px",
    width: "200px",
  },
};
const useStyles = makeStyles(customStyles);
export default function WV_and_ER_metrix(props, context) {
  const classes = useStyles();
  const [workersvoicechannels, setworkersvoicechannels] = useState(0);
  const [workersreceivingremedition, setworkersreceivingremedition] = useState(
    0
  );
  const [workerspredeparture, setworkerspredeparture] = useState(0);
  const [workerspredeparturene, setworkerspredeparturene] = useState(0);
  const [workerspredeparturekh, setWorkerspredeparturekh] = useState(0);
  const [workerstrained, setworkerstrained] = useState(0);
  const [recruitmentagenciestrained, setrecruitmentagenciestrained] = useState(
    0
  );
  const [remediationRecruitment, setRemediationRecruitment] = useState(0);
  const [
    recruitmentRelatedFeesDetected,
    setRecruitmentRelatedFeesDetected,
  ] = useState(0);
  const [supplierstrained, setsupplierstrained] = useState(0);
  const [alert, setAlert] = useState(null);
  const [monthYear, setMonthYear] = useState(null);
  const handleConfirmSuccessAlert = () => {
    props.history.push("/admin/issara-dashboard");
  };

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        onConfirm={handleConfirmSuccessAlert}
        confirmBtnCssClass={classes.button + " " + classes.success}
        title={context.t("Well done!")}
      >
        {context.t("record has been successfully created")}
      </SweetAlert>
    );
  };
  const errorAlert = (error) => {
    console.log("ERRROR", error);
    setAlert(
      <SweetAlert
        danger
        onConfirm={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        title="Error"
      >
        {error.message}
      </SweetAlert>
    );
  };
  const onSubmit = () => {
    const payload = {
      month: monthYear instanceof Date ? monthYear.toLocaleDateString() : null,
      workers_voice_channels: workersvoicechannels,
      workers_receiving_remedition: workersreceivingremedition,
      workers_pre_departure_mm: workerspredeparture,
      workers_pre_departure_ne: workerspredeparturene,
      workers_pre_departure_kh: workerspredeparturekh,
      workers_trained: workerstrained,
      recruitment_agencies_trained: recruitmentagenciestrained,
      remediation_for_recruitment_fees_and_cases: remediationRecruitment,
      recruitment_related_fees_detected_and_intercepted: recruitmentRelatedFeesDetected,
      suppliers_trained: supplierstrained,
    };
    if (monthYear !== null) {
      document.getElementById("dateError").style.display = "none";
      HttpService.post(
        `${process.env.REACT_APP_API_URL}/er-metrics/`,
        payload,
        (resp) => {
          successAlert();
        },
        (error) => {
          console.log(error);
          errorAlert();
        }
      );
    } else {
      document.getElementById("dateError").style.display = "block";
    }
  };
  return (
    <GridItem>
      <Card style={{ marginTop: 0 }}>
        {alert}
        <CardBody>
          <GridContainer>
            <GridItem>
              <p className={classes.label} style={{ marginBottom: 0 }}>
                {context.t("Month Year")}
              </p>
              <Datetime
                locale="en-US"
                // locale={getMomentLocale(lang)}
                dateFormat="YYYY-MM"
                inputProps={{
                  placeholder: context.t("Click here to open calendar *"),
                }}
                closeOnSelect={true}
                onChange={(date) => setMonthYear(date._d)}
                value={monthYear}
              />
              <p id="dateError" style={{ display: "none", color: "red" }}>
                {context.t("Date field is required")}
              </p>
            </GridItem>
            <GridContainer
              justify="left"
              style={{ padding: 0 }}
              className="changeLableStyle"
            >
              <GridItem className={classes.customGridItem}>
                <CustomInput
                  labelText={context.t(
                    "No. of New Workers Connected To Workers Voice Channels"
                  )}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    className: classes.input,
                    type: "number",
                    onChange: (e) => {
                      setworkersvoicechannels(
                        e.target.value > 0 ? e.target.value : 0
                      );
                    },
                  }}
                  value={`${parseInt(workersvoicechannels)}`}
                />
              </GridItem>
              <GridItem className={classes.customGridItem}>
                <CustomInput
                  labelText={context.t("No. of Workers Receiving Remedition")}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    className: classes.input,
                    type: "number",
                    onChange: (e) => {
                      setworkersreceivingremedition(
                        e.target.value > 0 ? e.target.value : 0
                      );
                    },
                  }}
                  value={`${parseInt(workersreceivingremedition)}`}
                />
              </GridItem>
              <GridItem className={classes.customGridItem}>
                <CustomInput
                  labelText={context.t(
                    "No. of Workers Reached in Pre-Departure-Nepal"
                  )}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    className: classes.input,
                    type: "number",
                    onChange: (e) => {
                      setworkerspredeparturene(
                        e.target.value > 0 ? e.target.value : 0
                      );
                    },
                  }}
                  value={`${parseInt(workerspredeparturene)}`}
                />
              </GridItem>
              <GridItem className={classes.customGridItem}>
                <CustomInput
                  labelText={context.t(
                    "No. of Workers Reached in Pre-Departure-Cambodia"
                  )}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    className: classes.input,
                    type: "number",
                    onChange: (e) => {
                      setWorkerspredeparturekh(
                        e.target.value > 0 ? e.target.value : 0
                      );
                    },
                  }}
                  value={`${parseInt(workerspredeparturekh)}`}
                />
              </GridItem>
            </GridContainer>
            <GridContainer
              justify="center"
              style={{ padding: 0 }}
              className="inputstyle"
            >
              <GridItem className={classes.customGridItem}>
                <CustomInput
                  labelText={context.t(
                    "No. of Workers Reached in Pre-Departure-Myanmar"
                  )}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    className: classes.input,
                    type: "number",
                    onChange: (e) => {
                      setworkerspredeparture(
                        e.target.value > 0 ? e.target.value : 0
                      );
                    },
                  }}
                  value={`${parseInt(workerspredeparture)}`}
                />
              </GridItem>
              <GridItem className={classes.customGridItem}>
                <CustomInput
                  labelText={context.t("No. of Recruitment Agencies Trained")}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    className: classes.input,
                    type: "number",
                    onChange: (e) => {
                      setrecruitmentagenciestrained(
                        e.target.value > 0 ? e.target.value : 0
                      );
                    },
                  }}
                  value={`${parseInt(recruitmentagenciestrained)}`}
                />
              </GridItem>
              <GridItem className={classes.customGridItem}>
                <CustomInput
                  labelText={context.t("No. of Suppliers Trained")}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    className: classes.input,
                    type: "number",
                    onChange: (e) => {
                      setsupplierstrained(
                        e.target.value > 0 ? e.target.value : 0
                      );
                    },
                  }}
                  value={`${parseInt(supplierstrained)}`}
                />
              </GridItem>
              <GridItem className={classes.customGridItem}>
                <CustomInput
                  labelText={context.t(
                    "Remediation for Recruitment Fees and Cases in USD. (change “0” to $)"
                  )}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    className: classes.input,
                    type: "number",
                    onChange: (e) => {
                      setRemediationRecruitment(
                        e.target.value > 0 ? e.target.value : 0
                      );
                    },
                  }}
                  value={`${parseInt(remediationRecruitment)}`}
                />
              </GridItem>
              <GridItem className={classes.customGridItem}>
                <CustomInput
                  labelText={context.t(
                    "Recruitment related Fees Detected and intercepted/prevented from job seekers and workers in USD. (change “0” to $)"
                  )}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    className: classes.input,
                    type: "number",
                    onChange: (e) => {
                      setRecruitmentRelatedFeesDetected(
                        e.target.value > 0 ? e.target.value : 0
                      );
                    },
                  }}
                  value={`${parseInt(recruitmentRelatedFeesDetected)}`}
                />
              </GridItem>
              <GridItem className={classes.customGridItem}>
                <CustomInput
                  labelText={context.t(
                    "No. of Trained Worker Leaders/Mobilizers"
                  )}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    className: classes.input,
                    type: "number",
                    onChange: (e) => {
                      setworkerstrained(
                        e.target.value > 0 ? e.target.value : 0
                      );
                    },
                  }}
                  value={`${parseInt(workerstrained)}`}
                />
              </GridItem>
            </GridContainer>
          </GridContainer>
          <GridContainer justify="flex-end">
            <GridItem>
              <Button color="success" onClick={onSubmit}>
                {context.t("Save")}
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </GridItem>
  );
}

WV_and_ER_metrix.contextTypes = {
  t: PropTypes.func.isRequired,
};
