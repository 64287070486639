import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  Grid,
  Hidden,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
} from "@material-ui/core";
import DonutImpact from "../../../components/Charts/DonutChart.js";
import { makeStyles } from "@material-ui/core/styles";
import "./css/style1.css";
import HttpService from "services/HttpService.js";
import loginStore from "redux/stores/LoginStore.js";
import LinearProgress from "@material-ui/core/LinearProgress";
import { fetchWorkPlanData } from "redux/actions/SpWorkplanFormAction.js";
import Utils from "../../../services/utils.js";
import axios from "axios";
import SupplierPerformanceGrid from "./SupplierPerformanceGrid";
import SPPriorityWorkPlanActivity from "./SPPriorityWorkPlanActivity";
import SingleHorizontalBar, {
  createDataPoint,
} from "../../../components/barchart/SingleHorizontalBar.js";
import HorizontalBarModal from "../../Modal/HorizontalBarModal.js";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import IndustriesSubIndustriesDropdown from "components/ilmdb/IndustriesSubIndustriesDropdown.js";

const useStyles = makeStyles({
  contentRight: {
    textAlign: "right",
  },
  table: {
    minWidth: 400,
  },
  topHeading: {
    borderRadius: "0",
    backgroundColor: "#999999",
    color: "#fff",
    fontWeight: "500",
    padding: "18px",
    width: "100%",
  },
  theadtext: {
    color: "black",
    fontWeight: "600",
    fontSize: "16px",
  },
  gaugeContainer: {
    textAlign: "center",
    justifyContent: "center",
    height: "180px",
    display: "flex",
  },
  gaugeBox: {
    minHeight: "10rem",
    justifyContent: "center",
    margin: "10px",
    boxShadow: "0px 1px 5px #d1d1d1",
  },
  notes: {
    width: "100%",
    minHeight: "5rem",
    background: "whitesmoke",
    borderRadius: "11px",
    padding: "11px",
  },
  gaugeTableText: {
    fontSize: "13px",
    fontWeight: 500,
    marginTop: "-30px",
  },
  container: {
    flex: 1,
    alignItems: "center",
  },
  title: {
    fontSize: 24,
    margin: 10,
  },
  workplanBox: {
    backgroundColor: "white",
    padding: "8px 32px 48px 32px",
    margin: "24px 12px",
    borderRadius: "8px",
  },
  tealText: {
    color: "#148696",
  },
  boldText: {
    fontWeight: "bold",
  },
  dialogBorderSides: {
    borderLeft: "1px solid gray",
    borderRight: "1px solid gray",
  },
  dialogBorderBottom: {
    borderBottom: "1px solid gray",
  },
  dialogPadding: {
    padding: "12px 0",
  },
  dialogBottom: {
    marginBottom: "-1px",
  },
  tealColor: {
    color: "teal",
  },
  boxCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
});

// TODO: Move to utils
const colorScheme = {
  POOR: "#a3199c",
  FAIR: "#fdc161",
  GOOD: "#a6dfdd",
  EXCELLENT: "#148696",
  NOT_YET: "#b7b7b7",
  PROGRESS: "#ffe599",
  COMPLETE: "#148696",
  NO_WORKER_VOICE: "#666666",
  RED: "#e57373",
  GREEN: "#81c784",
};

export const createStatusLegend = (
  vertical = false,
  size = 14,
  center = true
) => {
  const layout = vertical ? "column" : "row";
  const justify = center ? "center" : "flex-start";

  return (
    <Grid
      container
      item
      direction={layout}
      spacing={2}
      justifyContent={justify}
    >
      {Object.keys(Utils.defaultStatusText).map((key) => {
        return (
          <Grid item key={key}>
            {Utils.getStatusColor(null, key, true, size)}
          </Grid>
        );
      })}
    </Grid>
  );
};

const EngagementModal = ({ classes, open, setOpen, items = [] }) => {
  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth={true}
      onClose={() => setOpen(false)}
    >
      <DialogTitle className={classes.tealText}>
        {"Supplier List for Worker Voice Categories"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {/* none */}
          {items.length === 0 && (
            <Grid item xs>
              {"No data to display"}
            </Grid>
          )}
          {items.length !== 0 && (
            <>
              <Hidden xsDown>
                <Grid container item xs={12} spacing={2}>
                  {items.map((item, index) => {
                    return (
                      <Grid
                        key={item.heading}
                        item
                        xs={4}
                        className={`${classes.boldText} ${
                          classes.dialogBorderBottom
                        } ${index === 1 ? classes.dialogBorderSides : ""}`}
                      >
                        {item.heading}
                      </Grid>
                    );
                  })}
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  spacing={2}
                  className={`${classes.dialogBorderBottom} ${classes.dialogBottom}`}
                >
                  {items.map((item, index) => {
                    return (
                      <Grid
                        key={`${item.heading}-values`}
                        container
                        item
                        xs={4}
                        direction="column"
                        className={`${classes.dialogPadding} ${
                          index === 1 ? classes.dialogBorderSides : ""
                        }`}
                      >
                        {item.values &&
                          item.values.map((name, index) => (
                            <Grid item key={index}>
                              {name}
                            </Grid>
                          ))}
                      </Grid>
                    );
                  })}
                </Grid>
              </Hidden>

              <Hidden smUp>
                <Grid container item xs={12} direction="column">
                  {items.map((item) => {
                    return (
                      <React.Fragment key={item.heading}>
                        <Grid
                          item
                          className={`${classes.boldText} ${classes.dialogBorderBottom} ${classes.dialogPadding}`}
                        >
                          {item.heading}
                        </Grid>

                        <Grid
                          item
                          className={`${classes.dialogPadding} ${classes.dialogBorderBottom}`}
                        >
                          {item.values?.map((name) => {
                            return (
                              <Grid key={name} item>
                                {name}
                              </Grid>
                            );
                          })}
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                </Grid>
              </Hidden>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          className={classes.tealText}
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function SPProgressDashboard(props, context) {
  let _user = loginStore.getLoginUser();
  const dispatch = useDispatch();
  const classes = useStyles();
  const workPlanItem = useSelector((state) => state.workplanReducer.item);
  const [chartData, setChartData] = useState([]);
  const [workPlanData, setWorkPlanData] = useState({});
  const [actualChartData, setActualChartData] = useState([]);
  const [activitiesData, setActivitiesData] = useState([]);
  const [progressIndicatorData, setProgressIndicatorData] = useState([]);
  const [activeSuppliers, setActiveSuppliers] = useState({});
  const [wvChartData, setWvChartData] = useState({});
  const [supplierPerformance, setSupplierPerformance] = useState({});
  const [issuesData, setIssuesData] = useState({});
  const [reimbursementData, setReimbursementData] = useState({});
  const [industriesData, setIndustriesData] = useState({});
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [internationalStandards, setInternationalStandards] = useState(false);
  const [communicatedToSupp, setCommunicatedToSupp] = useState(false);
  const [commercialProcess, setCommercialProcess] = useState(false);
  const [wcInternationalSnD, setWcInternationalSnD] = useState(false);
  const [wcCommunicatedToS, setWcCommunicatedToS] = useState(false);
  const [wcCommercialProcesses, setWcCommercialProcesses] = useState(false);
  const [supplierCRC, setSupplierCRC] = useState();
  const [strategicPartner, setStrategicPartner] = useState(null);
  const [engagementModalOpen, setEngagementModalOpen] = useState(false);
  const [wv1TipOpen, setWV1TipOpen] = useState(false);
  const [openTooltips, setOpenTooltips] = useState({
    timeliness: false,
    quality: false,
    openness: false,
  });
  const defaultChartModalData = {
    open: false,
    title: "",
    items: [],
  };
  const [chartModalData, setChartModalData] = useState(defaultChartModalData);

  const onChartModalClose = () => {
    setChartModalData(defaultChartModalData);
  };

  const [selectedIndustryIds, setSelectedIndustryIds] = useState([]);
  const [selectedSubIndustryIds, setSelectedSubIndustryIds] = useState([]);

  const onChartModalOpen = (title, items, tooltip) => {
    setChartModalData({
      open: true,
      title: title,
      items: items,
    });
  };

  const handleTooltipOpen = (field) => {
    setOpenTooltips((prev) => ({ ...prev, [field]: true }));
    setTimeout(() => {
      setOpenTooltips((prev) => ({ ...prev, [field]: false }));
    }, 5000);
  };

  const handleIndustrySubindustryDropdownSelect = (selection) => {
    let params = {};
    setSelectedIndustryIds(selection.industry_id);
    setSelectedSubIndustryIds(selection.subindustry_id);

    if (selection.industry_id?.length > 0) {
      params.industry_id = selection.industry_id;
    }

    if (selection.subindustry_id?.length > 0) {
      params.subindustry_id = selection.subindustry_id;
    }

    if (params.industry_id && params.subindustry_id) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/sp-progress-dashboard/supplier-performances/`,
          {
            params: params,
            paramsSerializer: (params) => {
              const queryString = Object.keys(params)
                .map((key) => {
                  if (Array.isArray(params[key])) {
                    return params[key]
                      .map((value) => `${key}=${encodeURIComponent(value)}`)
                      .join("&");
                  }
                  return `${key}=${encodeURIComponent(params[key])}`;
                })
                .join("&");
              return queryString;
            },
          }
        )
        .then((response) => {
          if (response.data) {
            setSelectedIndustry({
              total_avg_score: response.data.total_avg_score,
            });
          }
        })
        .catch((err) => {
          console.error("Error fetching other industry data", err);
        });
    } else {
      setSelectedIndustry(null);
    }
  };

  useEffect(() => {
    !workPlanItem.loading &&
      !workPlanItem.fetched &&
      dispatch(fetchWorkPlanData());
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const strategic_partner = props.partner || _user.partner;
        const [
          suppliersResponse,
          wvChartResponse,
          supplierPerformanceResponse,
          issuesResponse,
          reimbursementResponse,
          IndustriesResponse,
        ] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_API_URL}/sp-progress-dashboard/suppliers/?sp=${strategic_partner}`
          ),
          axios.get(
            `${process.env.REACT_APP_API_URL}/sp-progress-dashboard/wv-bar-chart/?sp=${strategic_partner}`
          ),
          axios.get(
            `${process.env.REACT_APP_API_URL}/sp-progress-dashboard/supplier-performances/?sp=${strategic_partner}`
          ),
          axios.get(
            `${process.env.REACT_APP_API_URL}/sp-progress-dashboard/issues/?sp=${strategic_partner}`
          ),
          axios.get(
            `${process.env.REACT_APP_API_URL}/sp-progress-dashboard/worker-reimbursement/?sp=${strategic_partner}`
          ),
          axios.get(
            `${process.env.REACT_APP_API_URL}/industries/?sp=${strategic_partner}`
          ),
        ]);

        setActiveSuppliers(suppliersResponse.data);
        setWvChartData(wvChartResponse.data);
        setSupplierPerformance(supplierPerformanceResponse.data);
        setIssuesData(issuesResponse.data);
        setReimbursementData(reimbursementResponse.data);
        setIndustriesData(IndustriesResponse.data.results);
      } catch (err) {
        console.error("Error fetching data", err);
      }
    };

    fetchData();
  }, [props.partner]);

  useEffect(() => {
    if (workPlanItem.fetched) {
      setWorkPlanData(workPlanItem?.data);
    }
  }, [workPlanItem]);

  useEffect(() => {
    if (workPlanData) {
      setProgressIndicatorData(workPlanData.progress_indicator);
      setActivitiesData(
        Utils.flattenSPAcitivitesDataArray(workPlanData.priority_activity)
      );
    }
  }, [workPlanData]);

  useEffect(() => {
    HttpService.get(
      `${process.env.REACT_APP_API_URL}/activity_progress/?sp=${
        _user.partner != null ? _user.partner : props.partner
      }`,
      (response) => {
        setActivitiesData(Utils.flattenSPAcitivitesDataArray(response.results));
      },
      (err) => {
        console.log("Error fetching activity progress metrics:", err.message);
      }
    );
    setChartData([]);
    setActualChartData([]);
  }, [props.partner]);

  let newupdatearray = [];
  if (activitiesData?.length > 0) {
    activitiesData.map((item, index) => {
      if (item.value === true) {
        newupdatearray.push(item);
      }
    });
  }

  newupdatearray = newupdatearray.sort((a, b) => {
    if (a.priority_activity_id < b.priority_activity_id) {
      return -1;
    }
  });

  useEffect(() => {
    HttpService.get(
      `${process.env.REACT_APP_API_URL}/sp_work_plan/?sp=${
        _user.partner != null ? _user.partner : props.partner
      }`,
      (response) => {
        if (response.hasOwnProperty("Result")) {
        } else {
          setStrategicPartner(response.strategic_partner);
          setChartData(response);
          for (let i = 0; i < response.key_activities.length; i++) {
            if (
              response.key_activities[i].area === "labour_recruitment" &&
              response.key_activities[i].str_value ===
                "In line with international standards and definitions"
            ) {
              setInternationalStandards(response.key_activities[i].value);
            }
            if (
              response.key_activities[i].area === "labour_recruitment" &&
              response.key_activities[i].str_value ===
                "Clearly communicated to suppliers, including effective date"
            ) {
              setCommunicatedToSupp(response.key_activities[i].value);
            }
            if (
              response.key_activities[i].area === "labour_recruitment" &&
              response.key_activities[i].str_value ===
                "Integrated into commercial processes"
            ) {
              setCommercialProcess(response.key_activities[i].value);
            }
            if (
              response.key_activities[i].area === "working_conditions" &&
              response.key_activities[i].str_value ===
                "In line with international standards and definitions"
            ) {
              setWcInternationalSnD(response.key_activities[i].value);
            }
            if (
              response.key_activities[i].area === "working_conditions" &&
              response.key_activities[i].str_value ===
                "Clearly communicated to suppliers"
            ) {
              setWcCommunicatedToS(response.key_activities[i].value);
            }
            if (
              response.key_activities[i].area === "working_conditions" &&
              response.key_activities[i].str_value ===
                "Integrated into commercial processes"
            ) {
              setWcCommercialProcesses(response.key_activities[i].value);
            }
          }
        }
      },
      (err) => {
        console.log("Error fetching SP workplan:", err.message);
      }
    );
    HttpService.get(
      `${process.env.REACT_APP_API_URL}/all_cases/?sp=${
        _user.partner != null ? _user.partner : props.partner
      }`,
      (response) => {
        setActualChartData(response);
      },
      (err) => {
        console.log("Error fetching all cases:", err.message);
      }
    );
  }, [props.partner]);

  useEffect(() => {
    if (strategicPartner !== null) {
      HttpService.get(
        `${process.env.REACT_APP_API_URL}/strategicpartners/${
          _user.partner != null ? _user.partner : props.partner
        }/supplier_crc_score/`,
        (response) => {
          setSupplierCRC(response);
        },
        (err) => {
          console.log("Error fetching strategic partners:", err.message);
        }
      );
    }
  }, [strategicPartner]);

  const [progressOne, setProgressOne] = useState();
  const [progressTwo, setProgressTwo] = useState();

  useEffect(() => {
    // ========== AREA 2. LABOUR RECRUITMENT ER INDICATOR 1. dynamic bottom strings =================
    if (
      (internationalStandards && communicatedToSupp && !commercialProcess) ||
      (internationalStandards && !communicatedToSupp && !commercialProcess) ||
      (!internationalStandards && !communicatedToSupp && !commercialProcess) ||
      (internationalStandards && communicatedToSupp && commercialProcess)
    ) {
      setProgressOne(
        <>
          <Grid item xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
          <Grid item xs={4}>
            {context.t(
              "Clearly communicated to suppliers, including effective date"
            )}
          </Grid>
          <Grid item xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
        </>
      );
    }
    if (
      (!internationalStandards && communicatedToSupp && commercialProcess) ||
      (!internationalStandards && communicatedToSupp && !commercialProcess)
    ) {
      setProgressOne(
        <>
          <Grid item xs={4}>
            {context.t(
              "Clearly communicated to suppliers, including effective date"
            )}
          </Grid>
          <Grid item xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
          <Grid item xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
        </>
      );
    }
    if (!internationalStandards && !communicatedToSupp && commercialProcess) {
      setProgressOne(
        <>
          <Grid item xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
          <Grid item xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
          <Grid item xs={4}>
            {context.t(
              "Clearly communicated to suppliers, including effective date"
            )}
          </Grid>
        </>
      );
    }
    if (internationalStandards && !communicatedToSupp && commercialProcess) {
      setProgressOne(
        <>
          <Grid item xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
          <Grid item xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
          <Grid item xs={4}>
            {context.t(
              "Clearly communicated to suppliers, including effective date"
            )}
          </Grid>
        </>
      );
    }
    if (!internationalStandards && communicatedToSupp && !commercialProcess) {
      setProgressOne(
        <>
          <Grid item xs={4}>
            {context.t(
              "Clearly communicated to suppliers, including effective date"
            )}
          </Grid>
          <Grid item xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
          <Grid item xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
        </>
      );
    }

    if (
      (wcInternationalSnD && wcCommunicatedToS && !wcCommercialProcesses) ||
      (wcInternationalSnD && !wcCommunicatedToS && !wcCommercialProcesses) ||
      (!wcInternationalSnD && !wcCommunicatedToS && !wcCommercialProcesses) ||
      (wcInternationalSnD && wcCommunicatedToS && wcCommercialProcesses)
    ) {
      setProgressTwo(
        <>
          <Grid item xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
          <Grid item xs={4}>
            {context.t("Clearly communicated to suppliers")}
          </Grid>
          <Grid item xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
        </>
      );
    }
    if (
      (!wcInternationalSnD && wcCommunicatedToS && wcCommercialProcesses) ||
      (!wcInternationalSnD && wcCommunicatedToS && !wcCommercialProcesses)
    ) {
      setProgressTwo(
        <>
          <Grid item xs={4}>
            {context.t("Clearly communicated to suppliers")}
          </Grid>
          <Grid item xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
          <Grid item xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
        </>
      );
    }
    if (!wcInternationalSnD && !wcCommunicatedToS && wcCommercialProcesses) {
      setProgressTwo(
        <>
          <Grid item xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
          <Grid item xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
          <Grid item xs={4}>
            {context.t("Clearly communicated to suppliers")}
          </Grid>
        </>
      );
    }
    if (wcInternationalSnD && !wcCommunicatedToS && wcCommercialProcesses) {
      setProgressTwo(
        <>
          <Grid item xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
          <Grid item xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
          <Grid item xs={4}>
            {context.t("Clearly communicated to suppliers")}
          </Grid>
        </>
      );
    }
    if (!wcInternationalSnD && wcCommunicatedToS && !wcCommercialProcesses) {
      setProgressTwo(
        <>
          <Grid item xs={4}>
            {context.t("Clearly communicated to suppliers")}
          </Grid>
          <Grid item xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
          <Grid item xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
        </>
      );
    }
  }, [
    internationalStandards,
    communicatedToSupp,
    commercialProcess,
    wcInternationalSnD,
    wcCommunicatedToS,
    wcCommercialProcesses,
  ]);

  const makeChartImpactIndicator = (
    indicatorName,
    indicatorText,
    indicatorChart,
    noteText
  ) => {
    return (
      <Grid container item xs={12} spacing={4} alignItems="flex-start">
        <Grid item xs={12}>
          <h5>
            <span className={`${classes.tealText} ${classes.boldText}`}>
              {indicatorName}
            </span>
            <br />
            <span>{indicatorText}</span>
          </h5>
        </Grid>

        <Grid container item xs={12} sm={6} direction="column">
          <Grid item>
            <Box height="3rem" />
          </Grid>
          <Grid item>{indicatorChart}</Grid>
        </Grid>

        <Grid container item xs={12} sm={6} direction="column">
          <Grid item>
            <h5>
              <strong>{context.t("Company Targets / Notes")}</strong>
            </h5>
          </Grid>

          <Grid item>
            <p className={classes.notes}>{noteText ?? ""}</p>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const makeChartERIndicator = (
    indicatorName,
    indicatorChart,
    noteText,
    nameBold = true
  ) => {
    return (
      <Grid container item xs={12}>
        <Box ml={2} width="100%">
          <Grid container spacing={4} alignItems="flex-start">
            <Grid item xs={12}>
              {nameBold ? (
                <h5 className={classes.boldText}>{indicatorName}</h5>
              ) : (
                <span>{indicatorName}</span>
              )}
            </Grid>

            <Grid container item xs={12} sm={6}>
              <Grid item xs={12}>
                <span className={classes.boldText}>
                  {context.t("Actual status")}
                </span>
              </Grid>

              <Tooltip
                title="Click for list of suppliers in each category"
                placement="top"
                arrow
                disableFocusListener
              >
                <Grid item xs={12}>
                  {indicatorChart}
                </Grid>
              </Tooltip>
            </Grid>

            <Grid container item xs={12} sm={6}>
              <Grid item xs={12}>
                <span className={classes.boldText}>
                  {context.t("Company Targets / Notes")}
                </span>
              </Grid>

              <Grid item xs={12}>
                <p className={classes.notes}>{noteText ?? ""}</p>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  };

  return (
    <Container>
      {actualChartData.length === 0 ? (
        <LinearProgress />
      ) : (
        <Box>
          <div className={classes.workplanBox}>
            <Grid container spacing={4}>
              <Grid container item spacing={4} xs={12}>
                <Grid item xs={12}>
                  <center>
                    <h2>{context.t("IMPACT INDICATORS")}</h2>
                  </center>
                </Grid>

                <Grid item xs={12}>
                  <h5>
                    <span className={`${classes.tealText} ${classes.boldText}`}>
                      IMPACT INDICATOR 1:
                    </span>
                    <br />
                    <span>
                      Supplier average performance responding to worker-reported
                      grievances and collaboration with Issara Institute
                    </span>
                    <div>
                      <br />
                      <strong className={classes.tealText}>
                        {context.t("Your Supply Chain")}
                      </strong>
                    </div>
                  </h5>
                </Grid>

                <Grid container item xs={12} spacing={2}>
                  <SupplierPerformanceGrid
                    performance={supplierPerformance?.total_avg_score}
                    openTooltips={openTooltips}
                    handleTooltipOpen={handleTooltipOpen}
                    context={context}
                  />
                  <Grid item xs={12} sm={12} md={12}>
                    <h4>
                      <strong className={classes.tealText}>
                        {context.t("Other Supply Chains")}
                      </strong>
                    </h4>
                    <h5>
                      <strong>
                        {context.t("Select Industry & Sub-Industry")}
                      </strong>
                    </h5>
                    <IndustriesSubIndustriesDropdown
                      onSelect={handleIndustrySubindustryDropdownSelect}
                      multiple={true}
                      industry_values={selectedIndustryIds}
                      subindustry_values={selectedSubIndustryIds}
                    />
                  </Grid>
                  {selectedIndustry?.total_avg_score && (
                    <Grid item xs={12}>
                      <SupplierPerformanceGrid
                        performance={selectedIndustry?.total_avg_score}
                        openTooltips={openTooltips}
                        handleTooltipOpen={handleTooltipOpen}
                        context={context}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {makeChartImpactIndicator(
                context.t("IMPACT INDICATOR 2:"),
                context.t(
                  "% of worker-reported issues in the supply chain resolved in under 60 days"
                ),
                <SingleHorizontalBar
                  data={[
                    createDataPoint(
                      colorScheme.RED,
                      issuesData?.issue_duration?.percentage_over_60,
                      "Over 60 days"
                    ),
                    createDataPoint(
                      colorScheme.GREEN,
                      issuesData?.issue_duration?.percentage_under_60,
                      "Under 60 days"
                    ),
                  ]}
                  displayUnit="%"
                />,
                progressIndicatorData?.length > 0 &&
                  progressIndicatorData.find(
                    (item) =>
                      item.str_value ==
                      "impact_indicator_2_issue_resolution_60days_percent_note"
                  )?.notes
              )}

              {makeChartImpactIndicator(
                "IMPACT INDICATOR 3:",
                "% of remediation efforts of specific issues resolved at a level of poor, fair, good, excellent",
                <SingleHorizontalBar
                  data={[
                    {
                      color: colorScheme.POOR,
                      value: issuesData?.issue_quality?.Poor,
                      legend: "Poor",
                    },
                    {
                      color: colorScheme.FAIR,
                      value: issuesData?.issue_quality?.Fair,
                      legend: "Fair",
                    },
                    {
                      color: colorScheme.GOOD,
                      value: issuesData?.issue_quality?.Good,
                      legend: "Good",
                    },
                    {
                      color: colorScheme.EXCELLENT,
                      value: issuesData?.issue_quality?.Excellent,
                      legend: "Excellent",
                    },
                  ]}
                  displayUnit="%"
                />,
                progressIndicatorData?.length > 0 &&
                  progressIndicatorData.find(
                    (item) =>
                      item.str_value ==
                      "impact_indicator_3_remediation_efforts_resolution_percent_note"
                  )?.notes
              )}

              {makeChartImpactIndicator(
                "IMPACT INDICATOR 4:",
                "Number of eligible workers reimbursed for recruitment fees they have paid",
                <SingleHorizontalBar
                  data={[
                    {
                      color: colorScheme.RED,
                      value:
                        reimbursementData?.impact_indicator_4
                          ?.total_reimbursement,
                      legend: "Eligible Workers Not Reimbursed",
                    },
                    {
                      color: colorScheme.GREEN,
                      value:
                        reimbursementData?.impact_indicator_4
                          ?.total_remaining_reimbursement,
                      legend: "Eligible Workers Reimbursed",
                    },
                  ]}
                />,
                progressIndicatorData?.length > 0 &&
                  progressIndicatorData.find(
                    (item) =>
                      item.str_value ==
                      "impact_indicator_4_eligible_workers_reimbursement_percent_note"
                  )?.notes
              )}

              {makeChartImpactIndicator(
                "IMPACT INDICATOR 5:",
                "Amount of money owed versus amount of money reimbursed to workers for recruitment fees that they have paid",
                <DonutImpact
                  type="donut"
                  sections={[
                    {
                      label: "Reimbursed ($)",
                      amount:
                        reimbursementData?.impact_indicator_5?.reimbursed || 0,
                    },
                    {
                      label: "Agreed and in process for reimbursement ($)",
                      amount:
                        reimbursementData?.impact_indicator_5
                          ?.total_agree_in_process || 0,
                    },
                    {
                      label: "Not agreed for reimbursement ($)",
                      amount:
                        reimbursementData?.impact_indicator_5
                          ?.total_not_agree || 0,
                    },
                  ]}
                  colors={[
                    colorScheme.EXCELLENT,
                    colorScheme.GOOD,
                    colorScheme.POOR,
                  ]}
                />,
                progressIndicatorData?.length > 0 &&
                  progressIndicatorData.find(
                    (item) =>
                      item.str_value ==
                      "impact_indicator_5_reimbursement_percent_note"
                  )?.notes
              )}
            </Grid>
          </div>

          <div className={classes.workplanBox}>
            <Grid container spacing={4}>
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <center>
                    <h2>{context.t("PROGRESS DASHBOARD")}</h2>
                    <h3>{context.t("INDICATORS FOR AREA 1 – WORKER VOICE")}</h3>
                  </center>
                  <h5>
                    <span className={`${classes.tealText} ${classes.boldText}`}>
                      {context.t("Worker Voice Indicator 1")}
                    </span>
                  </h5>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box ml={2}>
                    <h5>
                      <strong>
                        {context.t(
                          "Worker voice roll-out and ongoing engagement"
                        )}
                      </strong>
                    </h5>
                  </Box>
                </Grid>

                <Hidden xsDown>
                  <Grid item xs={4}>
                    <Box ml={2}>
                      <h5>
                        <strong>{context.t("Actual status")}</strong>
                      </h5>
                    </Box>
                  </Grid>
                </Hidden>

                <Grid item xs={12} sm={6}>
                  <Box className={classes.boxCenter}>
                    <FiberManualRecordIcon className={classes.tealText} />
                    &nbsp;
                    {context.t(
                      "List of Active suppliers with/without worker voice"
                    )}
                    &nbsp;
                    <DoubleArrowIcon className={classes.tealText} />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Tooltip
                    title="Click for list of suppliers in each category"
                    placement="top"
                    arrow
                    open={wv1TipOpen}
                    onOpen={() => setWV1TipOpen(true)}
                    onClose={() => setWV1TipOpen(false)}
                  >
                    <Box
                      ml={2}
                      onClick={() => {
                        setEngagementModalOpen(true);
                        setWV1TipOpen(false);
                      }}
                    >
                      <SingleHorizontalBar
                        data={[
                          {
                            color: Utils.defaultStatusText.not_yet.color,
                            value: activeSuppliers.no_wv_percentage,
                            legend: "No Worker Voice",
                          },
                          {
                            color: Utils.defaultStatusText.partial.color,
                            value: activeSuppliers.non_wv_engagement_percentage,
                            legend: "Without Supplier Engagement",
                          },
                          {
                            color: Utils.defaultStatusText.complete.color,
                            value: activeSuppliers.wv_engagement_percentage,
                            legend: "With Supplier Engagement",
                          },
                        ]}
                        displayUnit="%"
                      />
                    </Box>
                  </Tooltip>
                </Grid>

                <Hidden smUp>
                  <Grid item xs={12}></Grid>
                </Hidden>
                <Hidden xsDown>
                  <Grid container item xs={12} justifyContent="flex-end">
                    <Grid item xs={6}>
                      <Box ml={2}>
                        <h5>
                          <strong>
                            {context.t("Company Targets / Notes")}
                          </strong>
                        </h5>
                      </Box>
                    </Grid>
                  </Grid>
                </Hidden>

                <Grid item xs={12} sm={6}>
                  <Box ml={2}>
                    {context.t(
                      "Percentage of tier-1 suppliers (export level) in your supply chain with Issara worker voice channels available to workers"
                    )}
                  </Box>
                </Grid>

                <Hidden smUp>
                  <Grid item xs={12}>
                    <Box ml={2}>
                      <h5>
                        <strong>{context.t("Company Targets / Notes")}</strong>
                      </h5>
                    </Box>
                  </Grid>
                </Hidden>

                <Grid item xs={12} sm={6}>
                  <Box ml={2}>
                    <p className={classes.notes}>
                      {progressIndicatorData?.length > 0 &&
                        progressIndicatorData.find(
                          (item) =>
                            item.str_value ==
                            "percentage_tier1_suppliers_with_issara_worker_voice_channels_note"
                        )?.notes}
                    </p>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box ml={2}>
                    {context.t(
                      "Percentage of upstream (tier-2 and beyond) suppliers in your supply chain with Issara worker voice channels available to workers, based on supply chain information provided"
                    )}
                  </Box>
                </Grid>

                <Hidden smUp>
                  <Grid item xs={12}>
                    <Box ml={2}>
                      <h5>
                        <strong>{context.t("Company Targets / Notes")}</strong>
                      </h5>
                    </Box>
                  </Grid>
                </Hidden>

                <Grid item xs={12} sm={6}>
                  <Box ml={2}>
                    <p className={classes.notes}>
                      {progressIndicatorData?.length > 0 &&
                        progressIndicatorData.find(
                          (item) =>
                            item.str_value ==
                            "percentage_upstream_suppliers_with_issara_worker_voice_channels_note"
                        )?.notes}
                    </p>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <h5>
                    <span className={`${classes.tealText} ${classes.boldText}`}>
                      {context.t("Worker Voice Indicator 2")}
                    </span>
                  </h5>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box ml={2}>
                    <h5>
                      <strong>
                        {context.t(
                          "Policy & procedures review: Ethical grievance mechanisms policy is in force that:"
                        )}
                      </strong>
                    </h5>
                  </Box>
                </Grid>

                <Hidden xsDown>
                  <Grid container item xs={6} direction="column">
                    <Grid item>
                      <h5>
                        <strong>{context.t("Actual status")}</strong>
                      </h5>
                    </Grid>
                    {createStatusLegend(false, 10, false)}
                  </Grid>
                </Hidden>

                <Grid item xs={12} sm={6}>
                  <Box ml={2}>
                    {context.t(
                      "Covers requirements and mechanisms for managing grievances"
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} ml={2}>
                  <Box ml={2}>
                    {Utils.getStatusColor(
                      context,
                      (progressIndicatorData?.length > 0 &&
                        progressIndicatorData.find(
                          (item) =>
                            item.str_value ==
                            "covers_requirements_mechanisms_managing_grievances"
                        )?.progress_status) ||
                        "not yet"
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box ml={2}>
                    {context.t("Includes a clear non-retaliation policy")}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box ml={2}>
                    {Utils.getStatusColor(
                      context,
                      (progressIndicatorData?.length > 0 &&
                        progressIndicatorData.find(
                          (item) =>
                            item.str_value == "clear_non_retaliation_policy"
                        )?.progress_status) ||
                        "not yet"
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box ml={2}>
                    {context.t(
                      "Undergoes periodic review to identify means for continuous improvement"
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box ml={2}>
                    {Utils.getStatusColor(
                      context,
                      (progressIndicatorData?.length > 0 &&
                        progressIndicatorData.find(
                          (item) =>
                            item.str_value ==
                            "periodic_review_continuous_improvement"
                        )?.progress_status) ||
                        "not yet"
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <center>
                    <h3>
                      {context.t("INDICATORS FOR AREA 2 – LABOR RECRUITMENT")}
                    </h3>
                  </center>
                  <h5>
                    <span className={`${classes.tealText} ${classes.boldText}`}>
                      {context.t("ER Indicator 1")}
                    </span>
                  </h5>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box ml={2}>
                    <h5>
                      <strong>
                        {context.t(
                          "Policy & procedures review: Ethical recruitment / migrant recruitment laws and policies is in force that is:"
                        )}
                      </strong>
                    </h5>
                  </Box>
                </Grid>

                <Hidden xsDown>
                  <Grid container item xs={6} direction="column">
                    <Grid item>
                      <h5>
                        <strong>{context.t("Actual status")}</strong>
                      </h5>
                    </Grid>
                    {createStatusLegend(false, 10, false)}
                  </Grid>
                </Hidden>

                <Grid item xs={12} sm={6}>
                  <Box ml={2}>
                    {context.t(
                      "In line with international standards and definitions "
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box ml={2}>
                    {Utils.getStatusColor(
                      context,
                      (progressIndicatorData?.length > 0 &&
                        progressIndicatorData.find(
                          (item) =>
                            item.str_value ==
                            "international_standards_definitions"
                        )?.progress_status) ||
                        "not yet"
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box ml={2}>
                    {context.t("Clearly communicated to suppliers")}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box ml={2}>
                    {Utils.getStatusColor(
                      context,
                      (progressIndicatorData?.length > 0 &&
                        progressIndicatorData.find(
                          (item) =>
                            item.str_value == "communicated_to_suppliers"
                        )?.progress_status) ||
                        "not yet"
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box ml={2}>
                    {context.t(
                      "Integrated into commercial processes such as supplier scorecards and contracts"
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box ml={2}>
                    {Utils.getStatusColor(
                      context,
                      (progressIndicatorData?.length > 0 &&
                        progressIndicatorData.find(
                          (item) =>
                            item.str_value == "integrated_commercial_processes"
                        )?.progress_status) ||
                        "not yet"
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <h5>
                    <span className={`${classes.tealText} ${classes.boldText}`}>
                      {context.t("ER Indicator 3")}
                    </span>
                  </h5>
                </Grid>

                <Grid item xs={12}>
                  <Box ml={2}>
                    <h5>
                      <strong>
                        {context.t(
                          "Policy & procedures review: Management Service Agreements:"
                        )}
                      </strong>
                    </h5>
                  </Box>
                </Grid>

                {makeChartERIndicator(
                  context.t(
                    "Review of MSAs between employers/suppliers and recruitment agencies"
                  ),
                  <Box
                    onClick={() => {
                      onChartModalOpen(
                        "Supplier List for ER Indicator 3",
                        wvChartData[Utils.business_indicator.ER_3]?.suppliers
                      );
                    }}
                  >
                    <SingleHorizontalBar
                      data={[
                        {
                          color: Utils.defaultStatusText.not_yet.color,
                          value:
                            wvChartData[Utils.business_indicator.ER_3]
                              ?.not_yet_initiated,
                          legend: Utils.defaultStatusText.not_yet.name,
                        },
                        {
                          color: Utils.defaultStatusText.partial.color,
                          value:
                            wvChartData[Utils.business_indicator.ER_3]
                              ?.partial_progress,
                          legend: Utils.defaultStatusText.partial.name,
                        },
                        {
                          color: Utils.defaultStatusText.complete.color,
                          value:
                            wvChartData[Utils.business_indicator.ER_3]
                              ?.completed_ongoing,
                          legend: Utils.defaultStatusText.complete.name,
                        },
                      ]}
                      displayUnit="%"
                    />
                  </Box>,
                  progressIndicatorData?.length > 0 &&
                    progressIndicatorData.find(
                      (item) =>
                        item.str_value ==
                        "review_msas_employer_supplier_recruitment_note"
                    )?.notes,
                  false
                )}

                <Grid item xs={12} md={12} sm={12}>
                  <h5>
                    <span className={`${classes.tealText} ${classes.boldText}`}>
                      {context.t("ER Indicator 4")}
                    </span>
                  </h5>
                </Grid>

                {makeChartERIndicator(
                  context.t(
                    "Communications: Disclosure of recruitment agencies used"
                  ),
                  <Box
                    onClick={() => {
                      onChartModalOpen(
                        "Supplier List for ER Indicator 4",
                        wvChartData[Utils.business_indicator.ER_4]?.suppliers,
                        "er4Tip"
                      );
                    }}
                  >
                    <SingleHorizontalBar
                      data={[
                        {
                          color: Utils.defaultStatusText.not_yet.color,
                          value:
                            wvChartData[Utils.business_indicator.ER_4]
                              ?.not_yet_initiated,
                          legend: Utils.defaultStatusText.not_yet.name,
                        },
                        {
                          color: Utils.defaultStatusText.partial.color,
                          value:
                            wvChartData[Utils.business_indicator.ER_4]
                              ?.partial_progress,
                          legend: Utils.defaultStatusText.partial.name,
                        },
                        {
                          color: Utils.defaultStatusText.complete.color,
                          value:
                            wvChartData[Utils.business_indicator.ER_4]
                              ?.completed_ongoing,
                          legend: Utils.defaultStatusText.complete.name,
                        },
                      ]}
                      displayUnit="%"
                    />
                  </Box>,
                  progressIndicatorData?.length > 0 &&
                    progressIndicatorData.find(
                      (item) =>
                        item.str_value ==
                        "communication_disclosure_recruitment_agencies_note"
                    )?.notes
                )}

                <Grid item xs={12} md={12} sm={12}>
                  <h5>
                    <span className={`${classes.tealText} ${classes.boldText}`}>
                      {context.t("ER Indicator 6")}
                    </span>
                  </h5>
                </Grid>

                {makeChartERIndicator(
                  context.t("Recruitment fees surveys"),
                  <Box
                    onClick={() => {
                      onChartModalOpen(
                        "Supplier List for ER Indicator 6",
                        wvChartData[Utils.business_indicator.ER_6]?.suppliers
                      );
                    }}
                  >
                    <SingleHorizontalBar
                      data={[
                        createDataPoint(
                          Utils.defaultStatusText.not_yet.color,
                          wvChartData[Utils.business_indicator.ER_6]
                            ?.not_yet_initiated,
                          Utils.defaultStatusText.not_yet.name
                        ),
                        createDataPoint(
                          Utils.defaultStatusText.partial.color,
                          wvChartData[Utils.business_indicator.ER_6]
                            ?.partial_progress,
                          Utils.defaultStatusText.partial.name
                        ),
                        createDataPoint(
                          Utils.defaultStatusText.complete.color,
                          wvChartData[Utils.business_indicator.ER_6]
                            ?.completed_ongoing,
                          Utils.defaultStatusText.complete.name
                        ),
                      ]}
                      displayUnit="%"
                    />
                  </Box>,
                  progressIndicatorData?.length > 0 &&
                    progressIndicatorData.find(
                      (item) => item.str_value == "recruitment_survey_fees_note"
                    )?.notes
                )}

                <Grid item xs={12} md={12} sm={12}>
                  <h5>
                    <span className={`${classes.tealText} ${classes.boldText}`}>
                      {context.t("ER Indicator 7")}
                    </span>
                  </h5>
                </Grid>

                {makeChartERIndicator(
                  context.t(
                    "Institutional capacity building: Ethical recruitment trainings"
                  ),
                  <Box
                    onClick={() => {
                      onChartModalOpen(
                        "Supplier List for ER Indicator 7",
                        wvChartData[Utils.business_indicator.ER_7]?.suppliers
                      );
                    }}
                  >
                    <SingleHorizontalBar
                      data={[
                        createDataPoint(
                          Utils.defaultStatusText.not_yet.color,
                          wvChartData[Utils.business_indicator.ER_7]
                            ?.not_yet_initiated,
                          Utils.defaultStatusText.not_yet.name
                        ),
                        createDataPoint(
                          Utils.defaultStatusText.partial.color,
                          wvChartData[Utils.business_indicator.ER_7]
                            ?.partial_progress,
                          Utils.defaultStatusText.partial.name
                        ),
                        createDataPoint(
                          Utils.defaultStatusText.complete.color,
                          wvChartData[Utils.business_indicator.ER_7]
                            ?.completed_ongoing,
                          Utils.defaultStatusText.complete.name
                        ),
                      ]}
                      displayUnit="%"
                    />
                  </Box>,
                  progressIndicatorData?.length > 0 &&
                    progressIndicatorData.find(
                      (item) =>
                        item.str_value ==
                        "institutional_capacity_building_ethical_recruitment_trainings_note"
                    )?.notes
                )}

                <Grid item xs={12} md={12} sm={12}>
                  <Box mt={2}>
                    <center>
                      <h3>
                        {context.t(
                          "INDICATORS FOR AREA 3 – WORKING CONDITIONS"
                        )}
                      </h3>
                    </center>
                  </Box>
                </Grid>

                <Grid item xs={12} md={12} sm={12}>
                  <h5>
                    <span className={`${classes.tealText} ${classes.boldText}`}>
                      {context.t("WORK Indicator 1")}
                    </span>
                  </h5>
                </Grid>

                {makeChartERIndicator(
                  context.t("Worker satisfaction surveys"),
                  <Box
                    onClick={() => {
                      onChartModalOpen(
                        "Supplier List for WV Indicator 1",
                        wvChartData[Utils.business_indicator.WV_1]?.suppliers
                      );
                    }}
                  >
                    <SingleHorizontalBar
                      data={[
                        createDataPoint(
                          Utils.defaultStatusText.not_yet.color,
                          wvChartData[Utils.business_indicator.WV_1]
                            ?.not_yet_initiated,
                          Utils.defaultStatusText.not_yet.name
                        ),
                        createDataPoint(
                          Utils.defaultStatusText.partial.color,
                          wvChartData[Utils.business_indicator.WV_1]
                            ?.partial_progress,
                          Utils.defaultStatusText.partial.name
                        ),
                        createDataPoint(
                          Utils.defaultStatusText.complete.color,
                          wvChartData[Utils.business_indicator.WV_1]
                            ?.completed_ongoing,
                          Utils.defaultStatusText.complete.name
                        ),
                      ]}
                      displayUnit="%"
                    />
                  </Box>,
                  progressIndicatorData?.length > 0 &&
                    progressIndicatorData.find(
                      (item) =>
                        item.str_value == "worker_satisfaction_surveys_note"
                    )?.notes
                )}

                <Grid item xs={12} md={12} sm={12}>
                  <h5>
                    <span className={`${classes.tealText} ${classes.boldText}`}>
                      {context.t("WORK Indicator 2")}
                    </span>
                  </h5>
                </Grid>

                {makeChartERIndicator(
                  context.t(
                    "Institutional capacity building: Training of working level / technical staff"
                  ),
                  <Box
                    onClick={() => {
                      onChartModalOpen(
                        "Supplier List for WV Indicator 2",
                        wvChartData[Utils.business_indicator.WV_2]?.suppliers
                      );
                    }}
                  >
                    <SingleHorizontalBar
                      data={[
                        createDataPoint(
                          Utils.defaultStatusText.not_yet.color,
                          wvChartData[Utils.business_indicator.WV_2]
                            ?.not_yet_initiated,
                          Utils.defaultStatusText.not_yet.name
                        ),
                        createDataPoint(
                          Utils.defaultStatusText.partial.color,
                          wvChartData[Utils.business_indicator.WV_2]
                            ?.partial_progress,
                          Utils.defaultStatusText.partial.name
                        ),
                        createDataPoint(
                          Utils.defaultStatusText.complete.color,
                          wvChartData[Utils.business_indicator.WV_2]
                            ?.completed_ongoing,
                          Utils.defaultStatusText.complete.name
                        ),
                      ]}
                      displayUnit="%"
                    />
                  </Box>,
                  progressIndicatorData?.length > 0 &&
                    progressIndicatorData.find(
                      (item) =>
                        item.str_value ==
                        "institutional_capacity_building_training_working_technical_staff_note"
                    )?.notes
                )}

                <Grid item xs={12} md={12} sm={12}>
                  <h5>
                    <span className={`${classes.tealText} ${classes.boldText}`}>
                      {context.t("WORK Indicator 3")}
                    </span>
                  </h5>
                </Grid>

                {makeChartERIndicator(
                  context.t(
                    "Institutional capacity building: Training of management / leadership"
                  ),
                  <Box
                    onClick={() => {
                      onChartModalOpen(
                        "Supplier List for WV Indicator 3",
                        wvChartData[Utils.business_indicator.WV_3]?.suppliers
                      );
                    }}
                  >
                    <SingleHorizontalBar
                      data={[
                        createDataPoint(
                          Utils.defaultStatusText.not_yet.color,
                          wvChartData[Utils.business_indicator.WV_3]
                            ?.not_yet_initiated,
                          Utils.defaultStatusText.not_yet.name
                        ),
                        createDataPoint(
                          Utils.defaultStatusText.partial.color,
                          wvChartData[Utils.business_indicator.WV_3]
                            ?.partial_progress,
                          Utils.defaultStatusText.partial.name
                        ),
                        createDataPoint(
                          Utils.defaultStatusText.complete.color,
                          wvChartData[Utils.business_indicator.WV_3]
                            ?.completed_ongoing,
                          Utils.defaultStatusText.complete.name
                        ),
                      ]}
                      displayUnit="%"
                    />
                  </Box>,
                  progressIndicatorData?.length > 0 &&
                    progressIndicatorData.find(
                      (item) =>
                        item.str_value ==
                        "institutional_capacity_building_training_management_leadership_note"
                    )?.notes
                )}
              </Grid>
            </Grid>
          </div>

          <div className={classes.workplanBox}>
            <Grid container>
              <Grid item xs={12}>
                <center>
                  <h2>
                    {context.t("PROGRESS ON PRIORITY WORKPLAN ACTIVITIES")}
                  </h2>
                </center>
              </Grid>

              <Grid item xs={12}>
                <SPPriorityWorkPlanActivity
                  partner={props.partner || _user.partner}
                  activity_data={workPlanData.priority_activity}
                  context={context}
                />
              </Grid>
            </Grid>
          </div>
        </Box>
      )}
      <EngagementModal
        classes={classes}
        open={engagementModalOpen}
        setOpen={setEngagementModalOpen}
        items={
          activeSuppliers
            ? [
                {
                  heading: context.t("No Worker Voice"),
                  values: activeSuppliers.without_worker_voice,
                },
                {
                  heading: context.t("Without Supplier Engagement"),
                  values:
                    activeSuppliers.worker_voice_without_supplier_engagement,
                },
                {
                  heading: context.t("With Supplier Engagement"),
                  values: activeSuppliers.worker_voice_with_supplier_engagement,
                },
              ]
            : []
        }
      />
      <HorizontalBarModal
        open={chartModalData.open}
        onClose={onChartModalClose}
        title={chartData.title}
        suppliers={chartModalData.items}
      />
    </Container>
  );
}

SPProgressDashboard.contextTypes = {
  t: PropTypes.func.isRequired,
};
