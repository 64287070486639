import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import axios from "axios";
import Utils from "../../../services/utils.js";
import { ACTIVITY_SECTION_ARRAY } from "./activityConstants";
import { makeStyles } from "@material-ui/core/styles";
import { createStatusLegend } from "./SPProgressDashboard.js";

const useStyles = makeStyles({
  activitySummaryLabel: {
    fontSize: "14px",
    marginLeft: "16px",
    fontWeight: "bold",
  },
  activitySummarySublabel: {
    marginLeft: "32px",
    display: "inline-block",
  },
  activityPaper: {
    padding: "1rem",
    margin: "1rem 0",
    boxShadow: "1px 1px rgba(0, 0, 0, 0.3)",
    border: "1px solid rgba(0, 0, 0, 0.3)",
    borderRadius: "4px",
  },
  trainingText: {
    display: "inline-block",
  },
  tealText: {
    color: "#148696",
  },
  boldText: {
    fontWeight: "bold",
  },
  upper: {
    textTransform: "uppercase",
  },
});

const SPPriorityWorkPlanActivity = ({ partner, activity_data, context }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [trainings, setTrainings] = useState([]);

  useEffect(() => {
    if (activity_data) {
      setData(Utils.flattenSPAcitivitesDataArray(activity_data));
    }
  }, [activity_data]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/supply-chain/trainings/?sp=${partner}`
      )
      .then((response) => {
        setTrainings(response.data);
      })
      .catch((error) => {
        console.log("Error fetching supply chain's trainings", error);
      });
  }, []);

  const getSectionData = (sectionKey, items) => {
    let sectionData = data.filter(
      (activity) => activity.groupName === sectionKey
    );

    if (sectionData.length === 0) {
      sectionData = getDefaultSectionData(sectionKey, items);
    }

    return sectionData;
  };

  const getDefaultSectionData = (sectionKey, items) => {
    let defaultData = [];

    if (Array.isArray(items)) {
      defaultData = items.map((item, index) => {
        return {
          priority_activity_id: `${sectionKey}.${index}`,
          description: item,
          priority_status: "not_started",
        };
      });
    }

    return defaultData;
  };

  const GroupComponent = ({ sectionData }) =>
    sectionData.map((activity) => (
      <Grid
        container
        item
        key={`activity-${activity.priority_activity_id}`}
        xs={12}
      >
        <Grid item xs={12} sm={8}>
          <span className={classes.activitySummarySublabel}>
            {activity.description}
          </span>
        </Grid>
        <Grid xs={1} />
        <Grid item xs>
          {Utils.getStatusColor(context, activity.priority_status)}
        </Grid>
      </Grid>
    ));

  return (
    <Paper className={classes.activityPaper}>
      {ACTIVITY_SECTION_ARRAY.map((area) => (
        <Grid container spacing={4} key={area.area}>
          <Grid item xs={12}>
            <center>
              <h3>{context.t(area.area)}</h3>
            </center>
          </Grid>

          {createStatusLegend()}

          {area.activities.map(({ section, activities }) => (
            <React.Fragment key={section}>
              <Grid item xs={12}>
                <h5 className={`${classes.tealText} ${classes.boldText}`}>
                  {context.t(section)}
                </h5>
              </Grid>
              {activities.map(({ sectionKey, label, items }) => (
                <>
                  <Grid item xs={12} key={sectionKey}>
                    <span className={classes.activitySummaryLabel}>
                      {context.t(`${sectionKey}. ${label}`)}
                    </span>
                  </Grid>
                  <GroupComponent
                    sectionData={getSectionData(sectionKey, items)}
                  />
                </>
              ))}
            </React.Fragment>
          ))}
        </Grid>
      ))}
      {trainings?.length > 0 && (
        <Grid container item xs={12} spacing={4}>
          <Grid item xs={12}>
            <center>
              <h3 className={classes.upper}>
                {context.t("List of Traingings Joined")}
              </h3>
            </center>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={8}>
              <span className={classes.boldText}>Training</span>
            </Grid>
            <Grid xs={1} />
            <Grid item xs>
              <span className={classes.boldText}>Date</span>
            </Grid>
          </Hidden>
          {trainings.map((training, index) => (
            <React.Fragment key={`training-${training.id}`}>
              <Grid item xs={12} sm={8}>
                <span className={classes.boldText}>{`${index + 1}.`}</span>
                <span>&nbsp;</span>
                <span>
                  {training.name}
                  {training.training_name
                    ? ` ${Utils.emDash} ${training.training_name}`
                    : ""}
                </span>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs>
                <span>{training.fieldwork_date || "None"}</span>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      )}
    </Paper>
  );
};

export default SPPriorityWorkPlanActivity;

SPPriorityWorkPlanActivity.contextTypes = {
  t: PropTypes.func.isRequired,
};
